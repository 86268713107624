import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2021-stories/more-stories"
import TextPDF from '../../components/spring-summer-2021-stories/text-pdf';

import Hero from '../../images/fall-winter-2020/presidents-message/hero-image2.jpg';
import framSignature from "../../images/fall-winter-2020/presidents-message/fram-signature.svg";

import socialBanner from "../../images/spring-summer-2021/social-banners/presidents-message.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'President’s Message';
    var pageDesc = 'During tough times, a Titan alumna spreads hope through feel-good holiday stories.';
    var slug = 'presidents-message';

    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${Hero})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      backgroundSize: 'cover',
      color: 'white'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>

        <TextPDF slug={slug} />
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>As we step into the light at the end of the dark pandemic tunnel, it is tempting to avoid looking back and focus solely on the illumination ahead that comes with the long-awaited return to in-person teaching and learning for fall 2021.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>To be clear, I am all for enjoying the warm glow of that fast-approaching light, but not without  recognizing the perseverant success of so many Titans who have served as bright spots during one of the most challenging times in our history — not by waiting for the light at the end of the tunnel, but by creating their own light in the midst of darker times and inviting all of us to bask in its warmth.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>With that in mind, we at Cal State Fullerton have spent the past few months recognizing and applauding many Titans who fit this laudable description through a variety of events and programs, including our annual faculty awards and the University Awards Program, while concurrently preparing for the biggest celebration of Titan perseverance and success of the academic year — our 2021 commencement ceremonies.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Titan magazine and its outstanding staff (who pivoted to produce the first, award-winning digital issue of this publication last spring) now add to this practice by sharing the inspirational work of a handful of Titans who spent the last several months persevering and succeeding beyond measure — from the Brar brothers’ achievements through the Male Success Initiative-Fullerton, and our linguistic faculty members preserving endangered languages, to alumna Cristina Jones transforming lives as an advocate for artists with disabilities, and alumna Lauren DuCharme’s role in the Mars Perseverance rover mission as a NASA/JPL systems flight engineer.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Indeed, these Titans personify the theme of our first-ever comprehensive philanthropic campaign, underscoring what it takes to illuminate the world — even in the most challenging of times.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>
            It takes high-impact educational practices.<br />
            It takes faculty, staff and students committed to equity, inclusion and social justice.<br />
            It takes alumni committed to both changing our world and exploring new ones.<br />
          </p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>In other words, It Takes a Titan. Every faculty, staff, student and alumni highlighted in this issue of Titan magazine gives us reason to celebrate our immediate past — as we look to an even brighter future.</p>
          </ScrollAnimation>
 
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p style={{lineHeight: '1.2em'}}>
            Sincerely,<br /><br />
            <img src={framSignature} alt="Fram Virjee" width="200px" /><br />
            Framroze M. Virjee, JD<br />
            President<br />
            California State University, Fullerton
          </p>
          </ScrollAnimation>

        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 